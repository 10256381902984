.new {
    display: flex;
    width: 100%;

    .newContainer {
        flex: 6;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 20px 10px 0px;
            width: 100%;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            div {
                display: flex;
                align-items: center;
            }

            .btn-add-new {

                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 6px 10px 4px 10px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;

                margin-left: 10px;

                // svg{
                //     font-size: 16px;
                // }
                span {
                    display: flex;
                    align-items: center;
                }
            }
        }

        form {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 30%;
                    color: #000;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }

                .formInput50 {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 48%;
                    color:#000;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }

                .formInput100 {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 100%;
                    color:#000;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }

            }

            .btn-submit {
                width: 150px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }

        }

        .flex {
            display: flex;
            padding: 5px;
            align-items: space;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .w-50 {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 48%;

            .label {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            .detail {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }
        }

    }
}

.basic-single {
    min-width: 100%;
    border-radius: 4px;
    border: none;
    border: 1px solid #42526e;
    box-sizing: border-box;
    background-color: #fff;
}
.text{
    float:unset;
}
.para-text{
    float:left;
}
.item-value{
    width: 65%;
 }
 input.searchBox {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23bbbbbb%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20class%3D%22feather%20feather-chevron-down%22%3E%3Cpolyline%20points%3D%220%200%200%2050%22%3E%3C%2Fpolyline%3E%3Cpolyline%20points%3D%2215%209%2021%2015%2027%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
    background-origin: content-box !important;
  }
  span.icon_down_dir {
    opacity: 0.5;
    scale: 0.9;

  }
  span.icon_down_dir:before {
    content: "";
  }